<template>
    <div class="loading-container">
        Carregant...
        <img src="@/assets/img/loading.gif"  alt="carregant" style="width: 150px; margin-top: 20px;">
    </div>
</template>

<script>
export default {
    name: 'Loader',
}
</script>

<style>
.loading-container {
    width: 100vw;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>