<template>
  <div class="about">
    <Header></Header>
    <div class="home-master-container">
      <div class="row mt-5 ml-0 mr-0" style="width: 100%;">
        <div class="col-sm-4"></div>
        <div class="col-sm-4">
          <div class="login-box">
            <h3 style="border-bottom: 1px solid; padding-bottom: 8px; font-size: 1.2rem;">Admin login</h3>
            <br>
            <form v-on:submit.prevent="onSubmit">
              Usuari
              <input type="text" v-model="user.name">
              <br><br>
              Contrasenya
              <input type="password" v-model="user.password">
              <br><br>
              <p v-if="error" class="error">Usuari o contrasenya incorrectes.</p>
              <input class="form-submit custom-button button-gray-blue" style="margin-left: 0; margin-top: 15px; width: 100%;" type="submit" value="Login" @click="SubmitLogin()">
            </form>
          </div> 
        </div>
        <div class="col-sm-4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import User from '../models/user';
import Header from '@/components/Header.vue'

export default {
  name: 'Login',
  components: {
    Header
  },
  data() {
    return {
      user: new User('', ''),
      //user: '',
      //password: '',

      error: false
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    document.title = 'Espai Poble - Admin panel'
    if (this.loggedIn) {
      this.$store.dispatch('changeCurrentView', 0)
    }
  },
  methods: {
    SubmitLogin() {
      if (this.user.name && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
          () => {
            this.$store.dispatch('changeCurrentView', 0)
          },
          error => {
            this.error = true;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
    }
  }
}
</script>
<style scoped>
.login-box {
  border-radius: 8px;
  box-shadow: 0px 3px 3px 3px rgba(0,0,0,0.1);
  padding: 32px;
  margin-top: 25%;
  background-color: rgb(245, 240, 237);
}
.error {
  color: red;
}
</style>