<template>
    <div class="">
        <div class="admin-sections-header-text" style="height: 32px;">
            <div style="flex: 1;">
                <img :src="require('@/assets/img/icon_event.png')" alt="Village's Events" class="header-icon">
                Esdeveniments de <span v-if="village">{{ village.name }}</span>
            </div>
            <div style="margin-right: 20px; height: 100%;"><input type="search" placeholder="Filtrar esdeveniments..." style="height: 100%;" v-model="text_filter"></div>
            <div class="custom-button button-green" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="newEvent">Nou esdeveniment</div>
            <div class="custom-button button-gray" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="backToVillages">Tornar a Pobles</div>
        </div>

        <table class="custom-table">
            <tr>
                <th>ID</th>
                <th>Títol</th>
                <th>Data</th>
                <th class="action-column">Multimèdia</th>
                <th class="action-column">Editar</th>
                <th class="action-column">Esborrar</th>
            </tr>
            <tr v-for="event in events_filtered" :key="event.id">
                <td>{{ event.id }}</td>
                <td>{{ event.title }}</td>
                <td><span v-if="parseInt(event.date_day) < 10">0</span>{{ event.date_day }} <span v-if="parseInt(event.date_day) > 0">-</span> {{ event.date_month }} </td>
                <td class="action-column"><img :src="require('@/assets/img/icon_image.png')" alt="Village's images" class="table-icon" @click="eventMedia(event.id, event.title)"></td>         
                <td class="action-column"><img :src="require('@/assets/img/icon_edit.png')" alt="Edit event" class="table-icon" @click="editEventModal(event.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_delete.png')" alt="Delete event" class="table-icon" @click="confirmDeleteModal(event.id)"></td>
            </tr>
        </table>

        <!-- New Event Modal -->
        <div class="admin-modal" v-if="newEventModal"><AdminNewEvent :villageId="selectedVillage"></AdminNewEvent></div>

        <!-- Edit Event Modal -->
        <div class="admin-modal" v-if="eventToEdit"><AdminNewEvent :editEvent="eventToEdit" :villageId="selectedVillage"></AdminNewEvent></div>

        <!-- Delete Event Modal -->
        <div class="admin-modal" v-if="eventToDelete">
            <div class="admin-sections-header-text">Esborrar esdeveniment</div>
            <div v-if="!eventDeletedOk">
                Esborrar aquest esdeveniment?
                <div class="buttons-container">
                    <div class="custom-button button-gray" @click="closeDeleteEvent()">Cancel·lar</div>
                    <div class="custom-button button-green" @click="deleteEvent()">Esborrar esdeveniment</div>
                </div>
            </div>
            <div v-else>
                Esdeveniment esborrat amb èxit
                <div class="buttons-container">
                    <div class="custom-button button-green" @click="closeDeleteEvent()">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, post } from '@/services/api.js'
import AdminNewEvent from '@/components/AdminNewEvent.vue'

export default {
    name: 'AdminVillagesEvents',
    components: {
        AdminNewEvent,
    },
    data() {
        return {
            village: null,
            events: [],
            events_filtered: [],
            text_filter: '',
            newEventModal: false,
            eventToEdit: null,
            eventToDelete: null,
            eventDeletedOk: false     
        }
    },
    computed: {
        selectedVillage () {
            return this.$store.getters.selectedVillage
        }
    },
    mounted () {
        this.getVillage()
        this.getEvents()
    },
    watch: {
        text_filter: function (val) {
            this.filterEvents(val)
        },
    },
    methods: {
        getVillage() {
            get('villages/' + this.selectedVillage)
            .then(response => {
                this.village = response.village[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        backToVillages() {
            this.$store.dispatch('setAdminTab', 0)
        },
        getEvents() {
            get('public/events/' + this.selectedVillage)
            .then(response => {
                this.events = response.events
                this.filterEvents(this.text_filter)
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        filterEvents(text) {
            this.events_filtered = []
            if (text != '') {
                this.events.forEach(event => {
                    if(event.title.toUpperCase().includes(text.toUpperCase())){
                        this.events_filtered.push(event)
                    }
                })
            }
            else {
                this.events_filtered = this.events
            }
        },
        newEvent() {
            this.newEventModal = true
        },
        editEventModal(eventId) {
            let url = 'events/' + eventId
            get(url)
            .then(response => {
                this.eventToEdit = response.event[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeEditEvent(){
            this.newEventModal = false
            this.eventToEdit = null
            this.getEvents()
        },
        confirmDeleteModal(eventId){
            this.eventToDelete = eventId
        },
        deleteEvent(){
            let url = 'events/' + this.eventToDelete + '/delete'
            post(url)
            .then(response => {
                this.eventDeletedOk = true
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeDeleteEvent() {
            this.eventToDelete = null
            this.eventDeletedOk = false
            this.getEvents()
        },
        eventMedia(eventId , eventName) {
            let entityData = {
                'type': 'event',
                'id': eventId,
                'name': eventName
            }
            this.$store.dispatch('changeSelectedEntity', entityData)
            this.$store.dispatch('setAdminTab', 3)
        }
    }
}
</script>
<style scoped>

</style>