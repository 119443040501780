<template>
    <div class="">
        <div v-if="editVillage" class="admin-sections-header-text">Editar poble</div>
        <div v-else class="admin-sections-header-text">Nou poble</div>
        <div class="separator"></div>
        <div v-if="!saveOk">
            <label for="name">Nom</label>
            <input type="text" name="name" id="name" v-model="newVillage.name">
            <label for="name">Nom - URL</label>
            <input type="text" name="nameurl" id="nameurl" v-model="newVillage.nameurl">
            <label for="desc">Descripció</label>
            <textarea name="desc" id="desc" v-model="newVillage.desc" style="margin-bottom: 10px;"></textarea>
            <label for="name">Comarca</label>
            <!-- <input type="text" name="comarca" id="comarca" v-model="newVillage.comarca"> -->
            <select v-model="newVillage.comarca">
                <option v-for="(comarca, index) in comarques" v-bind:key="index" :value="comarca">{{ comarca }}</option>
            </select>
            <label for="long">Long</label>
            <input type="text" name="long" id="long" v-model="newVillage.long">
            <label for="lat">Lat</label>
            <input type="text" name="lat" id="lat" v-model="newVillage.lat">
            <div v-if="formError" class="error-container">Hi ha camps obligatoris sense omplir</div>
            <div v-else style="height: 20px;"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Cancel·lar</div>
                <div v-if="editVillage" class="custom-button button-green" @click="saveVillage()">Actualitzar</div>
                <div v-else class="custom-button button-green" @click="saveVillage()">Crear poble</div>
            </div>
        </div>
        <div v-else>
            <div v-if="editVillage">Poble actualitzat</div>
            <div v-else>Nou poble creat</div>
            <div class="buttons-container">
                <div class="custom-button button-green" @click="closeModal()">OK</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { post } from '@/services/api.js'

export default {
    name: 'AdminNewVillage',
    /*computed: {
        currentAdminTab() {
            return this.$store.state.admin.currentTab;
        },
    },*/
    props: {
        editVillage: ''
    },        
    data() {
        return {
            newVillage: {
                name: '',
                nameurl: '',
                desc: '',
                comarca: '',
                long: '',
                lat: ''
            },
            formError: false,
            saveOk: false,
            comarques: ["L'Alt Camp", "L'Alt Empordà", "L'Alt Penedès", "L'Alt Urgell", "L'Alta Ribagorça", "L'Anoia", "El Bages", "El Baix Camp", "El Baix Ebre", "El Baix Empordà", "El Baix Llobregat", "El Baix Penedès", "El Barcelonès", "El Berguedà", "La Cerdanya", "La Conca de Barberà", "El Garraf", "Les Garrigues", "La Garrotxa", "El Gironès", "El Maresme", "El Montsià", "La Noguera", "Osona", "El Pallars Jussà", "El Pallars Sobirà", "El Pla de l'Estany", "El Pla d'Urgell", "El Priorat", "La Ribera d'Ebre", "El Ripollès", "La Segarra", "El Segrià", "La Selva", "El Solsonès", "El Tarragonès", "La Terra Alta", "L'Urgell", "La Vall d'Aran", "El Vallès Occidental", "El Vallès Oriental"]
        }
    },
    mounted() {
        if(this.editVillage) this.loadVillage()
    },
    methods: {
        loadVillage() {
            this.newVillage.name = this.editVillage.name
            this.newVillage.nameurl = this.editVillage.nameurl
            this.newVillage.desc = this.editVillage.desc
            this.newVillage.comarca = this.editVillage.comarca
            this.newVillage.long = this.editVillage.long
            this.newVillage.lat = this.editVillage.lat
        },
        closeModal() {
            this.$parent.closeEditVillage()
        },
        saveVillage() {
            if(this.newVillage.name == '' || this.newVillage.nameurl == '' || this.newVillage.long == '' || this.newVillage.lat == '') {
                this.formError = true
            }
            else {
                let objectToSend = {}
                objectToSend.name =  this.newVillage.name
                objectToSend.nameurl =  this.newVillage.nameurl
                objectToSend.desc =  this.newVillage.desc
                objectToSend.comarca =  this.newVillage.comarca
                objectToSend.long =  this.newVillage.long
                objectToSend.lat =  this.newVillage.lat

                let url = 'villages'
                if(this.editVillage) {
                    url += '/' + this.editVillage.id
                }
                post(url, objectToSend)
                .then(response => {
                    this.saveOk = true
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('user')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                })
            }
        }
    }
}
</script>
<style scoped>

</style>