<template>
    <div class="media-object-container">

        <!-- *****************  Thumbnail Image  ***************** -->
        <div
            v-if="media.url != undefined"
            class="media-image"
            :style="{
                backgroundImage: 'url(' + media.url + ')'
            }"
        >
        </div>
        <div v-else-if="media.filetype == 'image'" class="media-image no-image"></div>
        <div v-else-if="media.filetype == 'video'" class="media-image video-image"></div>
        <div v-else-if="media.filetype == 'audio'" class="media-image audio-image"></div>
        <div v-else class="media-image no-image"></div>

        <!-- *****************  Media Filename  ***************** -->
        <div class="media-name" v-if="media.path != undefined">
            {{ media.path.substring((media.path.lastIndexOf('/')) + 1) }}
        </div>
        <div class="media-name" v-else>
            Sense imatge
        </div>

        <!-- *****************  Event Description  ***************** -->
        <div v-if="media.entity == 'event' && media.ismain == 'false'" class="media-desc">
            {{ media.desc }}
        </div>


        <!-- *****************  Main Images Upload  ***************** -->
        <div v-if="media.entity == 'village' || media.ismain == 'true'">
            <input
                type="file"
                ref="imageInput"
                style="display: none"
                accept="image/*"
                @change="onFilePicked"
            />
            <!-- <div class="custom-button button-gray-green" @click="onPickFile('tecnic')" style="margin: 0; padding: 15px;">
                <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 15px; margin-right: 8px;">Sel·leccionar axiu
            </div> -->
            <div class="media-icon-container">
                <div>Sel·leccionar axiu</div>
                <img :src="require('@/assets/img/icon_upload.png')" alt="Upload file" class="media-icon" @click="onPickFile">
            </div>
        </div>


        <!-- *************  Add multimedia  **************-->
        <div v-if="media.entity == 'event' && media.ismain == 'false' && !media.multimedia_path" class="media-icon-container" style=" margin: 8px; min-height: 100px; padding-right: 20px; border-right: 1px solid rgba(0, 0, 0, 0.2);">
            <div class="custom-button button-gray-green" @click="addAudioVideoToElement()"  style="margin-right: 0; padding: 5px 20px; width: 90px; font-size: 0.7rem;">
                <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 10px; margin-right: 8px;">Àudio / Vídeo
            </div>
        </div>
        <div v-else-if="media.entity == 'event' && media.ismain == 'false' && media.multimedia_path" class="media-icon-container" style=" margin: 8px; min-height: 100px; padding-right: 20px; border-right: 1px solid rgba(0, 0, 0, 0.2);">
            <div v-if="media.multimedia_type == 'video'" class="media-image video-image"></div>
            <div v-if="media.multimedia_type == 'audio'" class="media-image audio-image"></div>
        </div>
    
        <!-- *****************  Delete  ***************** -->
        <div class="media-icon-container">
            <div>Esborrar axiu</div>
            <img v-if="media.url != undefined || media.filetype != 'image'" :src="require('@/assets/img/icon_delete.png')" alt="Delete location" class="media-icon" @click="deleteMedia">
            <img v-else :src="require('@/assets/img/icon_delete.png')" alt="Delete location" class="media-icon-disabled">
        </div>

    </div>
</template>
<script>
import { post, uploadFile } from '@/services/api.js'

export default {
    name: 'MediaElement',
    data() {
        return {
            desc: ''
        }
    },
    props: {
        media: Object
    },
    mounted () {

    },

    methods: {
        deleteMedia: function () {
            if (this.media.entity != 'event') {
                let url = 'files/deletefile'
                let objectToSend = {}
                objectToSend.path =  this.media.path
                post(url, objectToSend)
                .then(response => {
                    this.$parent.getEntityFiles()
                })
            }
            else {
                let url = 'files/deleteeventmedia'
                let objectToSend = {}
                objectToSend.id =  this.media.mediaid
                post(url, objectToSend)
                .then(response => {
                    if (this.media.multimedia_mediaid) {
                        objectToSend = {}
                        objectToSend.id =  this.media.multimedia_mediaid
                        post(url, objectToSend)
                        .then(res => {
                            this.$parent.getEntityFiles()
                        })
                    }
                    else {
                        this.$parent.getEntityFiles()
                    }             
                })
            }
        },

        onPickFile () {
            this.$refs.imageInput.click()
        },
        onFilePicked (event) {
            const files = event.target.files
            let url = 'files/store'
 
            const formData = new FormData();
            formData.append('entity', this.media.entity)
            formData.append('File', files[0])
            formData.append('id', this.media.id)
            formData.append('ismain', this.media.ismain)
            formData.append('desc', this.desc)

            uploadFile(url, formData)
            .then(response => {
                this.$parent.getEntityFiles()
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        addAudioVideoToElement () {
            this.$parent.show_modal = false;
            this.$parent.isMultimedia = true
            this.$parent.nameToSetMutimedia = this.media.path.substring(0, this.media.path.indexOf("."))

            let globalThis = this
            setTimeout( () => {
                globalThis.$parent.onPickFile()
            }, 50);
            
        }
    }
}
</script>
<style scoped>
.media-object-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* border: 1px solid rgba(0,0,0,0.5); */
    /* height: 120px; */
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #E2D2C3;
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.2)
}
.media-image {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.no-image {
    background-image: url('~@/assets/img/no-image.png');
}
.video-image {
    background-image: url('~@/assets/img/icon_video.png');
    background-size: 50%;
    opacity: 0.5;
}
.audio-image {
    background-image: url('~@/assets/img/icon_audio.png');
    background-size: 50%;
    opacity: 0.5;
}
.media-name {
    margin: 15px;
    flex: 1;
}
.media-desc {
    flex: 1;
    border-right: 1px solid rgba(0,0,0,0.2);
    padding: 0px 10px;
    min-height: 100px;
}
.media-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 30px;
    font-size: 0.9rem;
    opacity: 0.6;
}
.media-icon {
    width: 30px;
    cursor: pointer;
    margin: 15px;  
}
.media-icon-disabled {
    width: 30px;
    opacity: 0.2;
    margin: 15px;
}
</style>