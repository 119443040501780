<template>
    <div class="">
        <div class="admin-sections-header-text" style="height: 32px;">
            <div style="flex: 1;">
                <img :src="require('@/assets/img/icon_location.png')" alt="Village's locations" class="header-icon">
                Localitzacions de <span v-if="village">{{ village.name }}</span>
            </div>
            <div style="margin-right: 20px; height: 100%;"><input type="search" placeholder="Filtrar localitzacions..." style="height: 100%;" v-model="text_filter"></div>
            <div class="custom-button button-green" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="newLoc">Nova localització</div>
            <div class="custom-button button-gray" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="backToVillages">Tornar a Pobles</div>
        </div>

        <table class="custom-table">
            <tr>
                <th>ID</th>
                <th>Nom</th>
                <th>Tipus</th>
                <th class="action-column">Imatges</th>
                <th class="action-column">Editar</th>
                <th class="action-column">Esborrar</th>
            </tr>
            <tr v-for="loc in locs_filtered" :key="loc.id">
                <td>{{ loc.id }}</td>
                <td>{{ loc.name }}</td>
                <td>{{ loc.typeNames }}</td>
                <td class="action-column"><img :src="require('@/assets/img/icon_image.png')" alt="Village's images" class="table-icon" @click="locMedia(loc.id, loc.name)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_edit.png')" alt="Edit location" class="table-icon" @click="editLocModal(loc.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_delete.png')" alt="Delete location" class="table-icon" @click="confirmDeleteModal(loc.id)"></td>
            </tr>
        </table>

        <!-- New Loc Modal -->
        <div class="admin-modal" v-if="newLocModal"><AdminNewLoc :villageId="selectedVillage"></AdminNewLoc></div>

        <!-- Edit Loc Modal -->
        <div class="admin-modal" v-if="locToEdit"><AdminNewLoc :editLoc="locToEdit" :villageId="selectedVillage"></AdminNewLoc></div>

        <!-- Delete Loc Modal -->
        <div class="admin-modal" v-if="locToDelete">
            <div class="admin-sections-header-text">Esborrar localització</div>
            <div v-if="!locDeletedOk">
                Esborrar aquesta localització?
                <div class="buttons-container">
                    <div class="custom-button button-gray" @click="closeDeleteLoc()">Cancel·lar</div>
                    <div class="custom-button button-green" @click="deleteLoc()">Esborrar localització</div>
                </div>
            </div>
            <div v-else>
                Localització esborrada amb èxit
                <div class="buttons-container">
                    <div class="custom-button button-green" @click="closeDeleteLoc()">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, post } from '@/services/api.js'
import AdminNewLoc from '@/components/AdminNewLoc.vue'

export default {
    name: 'AdminVillagesLocations',
    components: {
        AdminNewLoc,
    },
    data() {
        return {
            village: null,
            locs: [],
            locs_filtered: [],
            text_filter: '',
            newLocModal: false,
            locToEdit: null,
            locToDelete: null,
            locDeletedOk: false     
        }
    },
    computed: {
        selectedVillage () {
            return this.$store.getters.selectedVillage
        },
        types () {
            return this.$store.getters.types
        }
    },
    mounted () {
        this.getVillage()
        this.getLocations()
    },
    watch: {
        text_filter: function (val) {
            this.filterLocs(val)
        },
    },
    methods: {
        getVillage() {
            get('villages/' + this.selectedVillage)
            .then(response => {
                this.village = response.village[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        backToVillages() {
            this.$store.dispatch('setAdminTab', 0)
        },
        getLocations() {
            this.locs = []
            get('public/locations/' + this.selectedVillage)
            .then(response => {
                response.locations.forEach(loc => {
                    loc.typeNames = this.formatTypeArray(loc.type)
                    this.locs.push(loc)
                })
                //this.locs = response.locations
                this.filterLocs(this.text_filter)
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        filterLocs(text) {
            this.locs_filtered = []
            if (text != '') {
                this.locs.forEach(loc => {
                    if(loc.name.toUpperCase().includes(text.toUpperCase()) || loc.typeNames.toUpperCase().includes(text.toUpperCase())){
                        this.locs_filtered.push(loc)
                    }
                })
            }
            else {
                this.locs_filtered = this.locs
            }
            
        },
        newLoc() {
            this.newLocModal = true
        },
        editLocModal(locId) {
            let url = 'locations/' + locId
            get(url)
            .then(response => {
                this.locToEdit = response.location[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeEditLoc(){
            this.newLocModal = false
            this.locToEdit = null
            this.getLocations()
        },
        confirmDeleteModal(locId){
            this.locToDelete = locId
        },
        deleteLoc(){
            let url = 'locations/' + this.locToDelete + '/delete'
            post(url)
            .then(response => {
                this.locDeletedOk = true
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeDeleteLoc() {
            this.locToDelete = null
            this.locDeletedOk = false
            this.getLocations()
        },
        locMedia(locId , locName) {
            let entityData = {
                'type': 'location', 
                'id': locId,
                'name': locName
            }
            this.$store.dispatch('changeSelectedEntity', entityData)
            this.$store.dispatch('setAdminTab', 3)
        },
        formatTypeArray(typestring) {
            console.log(typestring)
            let typeArrayString = ''
            let typeArray = typestring.split(",");
            for(let i = 0; i < typeArray.length; i++) {
                if(i == 0) {
                    typeArray[i] = typeArray[i].substring(1)
                }
                if(i == typeArray.length -1) {
                    typeArray[i] = typeArray[i].slice(0, -1)
                }
                typeArrayString += this.types[typeArray[i]]
                if (i < typeArray.length - 1) {
                    typeArrayString += ', '
                }
            }





            // let typeArray = typestring.split(",");
            // for(let i = 0; i < typeArray.length; i++) {
            //     if(i == 0) {
            //         typeArray[i] = typeArray[i].substring(1)
            //     }
            //     if(i == typeArray.length -1) {
            //         typeArray[i] = typeArray[i].slice(0, -1)
            //     }
            //     typeArray[i] = typeArray[i].substring(1)
            //     typeArray[i] = typeArray[i].slice(0, -1)
            //     if(typeArray[i].substring(0,4) == 'Come') {
            //         typeArray[i] = 'Comerç'
            //     }
            // }
            return typeArrayString
        }
    }
}
</script>
<style scoped>

</style>