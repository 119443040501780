<template>
    <div class="">
        <div class="admin-sections-header-text" style="height: 32px;">
            <div style="flex: 1;">Pobles</div>
            <div style="margin-right: 20px; height: 100%;"><input type="search" placeholder="Filtrar pobles..." style="height: 100%;" v-model="text_filter"></div>
            <div class="custom-button button-green" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="newVillage">Nou Poble</div>
        </div>

        <table class="custom-table">
            <tr>
                <th>ID</th>
                <th>Nom</th>
                <th class="action-column">Localitzacions</th>
                <th class="action-column">Esdeveniments</th>
                <th class="action-column">Imatges</th>
                <th class="action-column">Editar</th>
                <th class="action-column">Esborrar</th>
            </tr>
            <tr v-for="village in villages_filtered" :key="village.id">
                <td>{{ village.id }}</td>
                <td>{{ village.name }}</td>
                <td class="action-column"><img :src="require('@/assets/img/icon_location.png')" alt="Village's locations" class="table-icon" @click="villageLocations(village.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_event.png')" alt="Village's events" class="table-icon" @click="villageEvents(village.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_image.png')" alt="Village's images" class="table-icon" @click="villageMedia(village.id, village.name)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_edit.png')" alt="Edit village" class="table-icon" @click="editVillageModal(village.id)"></td>
                <td class="action-column"><img :src="require('@/assets/img/icon_delete.png')" alt="Delete village" class="table-icon" @click="confirmDeleteModal(village.id)"></td>
            </tr>
        </table>

        <!-- New Village Modal -->
        <div class="admin-modal" v-if="newVillageModal"><AdminNewVillage></AdminNewVillage></div>

        <!-- Villages locations Modal -->
        <!-- <div class="admin-modal" v-if="villageLocations"><AdminVillageLocations :villageLocations="villageLocations"></AdminVillageLocations></div> -->

        <!-- Edit Village Modal -->
        <div class="admin-modal" v-if="villageToEdit"><AdminNewVillage :editVillage="villageToEdit"></AdminNewVillage></div>

        <!-- Delete Village Modal -->
        <div class="admin-modal" v-if="villageToDelete">
            <div class="admin-sections-header-text">Esborrar poble</div>
            <div v-if="!villageDeletedOk">
                Esborrar aquest poble?
                <div class="buttons-container">
                    <div class="custom-button button-gray" @click="closeDeleteVillage()">Cancel·lar</div>
                    <div class="custom-button button-green" @click="deleteVillage()">Esborrar poble</div>
                </div>
            </div>
            <div v-else>
                Poble esborrat amb èxit
                <div class="buttons-container">
                    <div class="custom-button button-green" @click="closeDeleteVillage()">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, post } from '@/services/api.js'
import AdminNewVillage from '@/components/AdminNewVillage.vue'
// import AdminVillageLocations from '@/components/AdminVillageLocations.vue'

export default {
    name: 'AdminVillages',
    components: {
        AdminNewVillage,
        // AdminVillageLocations
    },
    data() {
      return {
        villages: [],
        villages_filtered: [],
        text_filter: '',
        newVillageModal: false,
        villageToEdit: null,
        villageToDelete: null,
        // villageLocations: null,
        villageDeletedOk: false,
      }
    },
    mounted () {
        this.getVillages()
    },
    watch: {
        text_filter: function (val) {
            this.filterVillages(val)
        },
    },
    methods: {
        getVillages() {
            get('villages')
            .then(response => {
                this.villages = response.villages
                this.filterVillages(this.text_filter)
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            })
        },
        filterVillages(text) {
            this.villages_filtered = []
            if (text != '') {
                this.villages.forEach(village => {
                    if(village.name.toUpperCase().includes(text.toUpperCase())){
                        this.villages_filtered.push(village)
                    }
                })
            }
            else {
                this.villages_filtered = this.villages
            }
        },
        newVillage() {
            this.newVillageModal = true
        },
        editVillageModal(villageId) {
            let url = 'villages/' + villageId
            get(url)
            .then(response => {
                this.villageToEdit = response.village[0]
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeEditVillage(){
            this.newVillageModal = false
            this.villageToEdit = null
            this.getVillages()
        },
        confirmDeleteModal(villageId){
            this.villageToDelete = villageId
        },
        deleteVillage(){
            let url = 'villages/' + this.villageToDelete + '/delete'
            post(url)
            .then(response => {
                this.villageDeletedOk = true
            })
            .catch(error => {
                console.log("ERROR: ", error.response.data.error)
                if(error.response.data.error.includes("token")){
                    localStorage.removeItem('user')
                    this.$store.dispatch('auth/logout')
                    this.$router.push('/login')
                }
            }) 
        },
        closeDeleteVillage() {
            this.villageToDelete = null
            this.villageDeletedOk = false
            this.getVillages()
        },
        villageLocations(villageId) {
            this.$store.dispatch('changeSelectedVillage', villageId)
            this.$store.dispatch('setAdminTab', 1)
        },
        villageEvents(villageId) {
            this.$store.dispatch('changeSelectedVillage', villageId)
            this.$store.dispatch('setAdminTab', 2)
        },
        villageMedia(villageId , villageName) {
            this.$store.dispatch('changeSelectedVillage', villageId)
            let entityData = {
                'type': 'village', 
                'id': villageId,
                'name': villageName
            }
            this.$store.dispatch('changeSelectedEntity', entityData)
            this.$store.dispatch('setAdminTab', 3)
        }
        // villageLocationsModal(villageId) {
        //     let url = 'villages/' + villageId
        //     get(url)
        //     .then(response => {
        //         this.villageLocations = response.village[0]
        //     })
        //     .catch(error => {
        //         console.log("ERROR: ", error.response.data.error)
        //         if(error.response.data.error.includes("token")){
        //             localStorage.removeItem('user')
        //             this.$store.dispatch('auth/logout')
        //             this.$router.push('/login')
        //         }
        //     }) 
        // },
        // closeVillageLocations() {
        //     this.villageLocations = null
        // },
    }
}
</script>
<style scoped>

</style>