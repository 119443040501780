<template>
    <div>
        <div id="header">
            <div id="logoheader">
                <router-link to="/">
                    Espai Poble
                    <!--<img :src="require('@/assets/img/logoarrels.svg')" alt="Logo Arrels"-->
                </router-link>
            </div>
            <div id="usernameheader">		
                <div id="userbutton" v-if="currentUser" @click="showUserOptions">{{ currentUser.user.name }} </div>
            </div>	  
        </div>
        <div id="useroptions" v-if="userOptionsVisible && !selectFinquesVisible">
            <!--<router-link to="/admin"><div v-if="currentUser.user.name == 'admin' && isAdminPanel == false" class="useroptions-element">Panel admin</div></router-link>	
            <router-link to="/"><div v-if="currentUser.user.name == 'admin' && isAdminPanel == true" class="useroptions-element">Tancar panel admin</div></router-link>	-->
            <!-- <div v-if="currentUser.user.name == 'admin' && isAdminPanel == false" class="useroptions-element" @click="toggleAdminPanel">Panell admin</div>
            <div v-if="currentUser.user.name == 'admin' && isAdminPanel == true" class="useroptions-element" @click="toggleAdminPanel">Tancar panell admin</div> -->
            <div class="useroptions-element" @click="logout">Sortir</div>
            <!-- <div class="useroptions-element" v-if="userFinques" @click="showSelectFinca">Canviar finca</div>
            <div class="useroptions-element">Canviar dades de l'usuari</div> -->
        </div>
        <div id="useroptions" v-if="selectFinquesVisible">
            <div class="useroptions-element" v-for="finca in userFinques" :key="finca.id" @click="selectFinca(finca)"> {{ finca.fincaname }} </div>
        </div>
    </div>
</template>
<script>
import AuthService from '@/services/auth.service';

export default {
    name: 'Header',
    computed: {
        currentUser() {
            return this.$store.state.auth.user
        },
        isAdminPanel() {
            return this.$store.state.admin.isAdminPanel
        },
        selectedFinca() {
            return this.$store.state.finques.selectedFinca
        },
        userFinques() {
            return this.$store.state.finques.userFinques
        }
    },
    data() {
      return {
        userOptionsVisible: false,
        selectFinquesVisible: false,
      }
    },
    created () {
        var thisGlobal = this
        document.onclick = function(e){
			if ((e.target.id !== 'useroptions') && (e.target.id !== 'userbutton')){
				thisGlobal.userOptionsVisible = false
			}
		};
    },
    methods: {
        showUserOptions() {		
            this.userOptionsVisible = !this.userOptionsVisible
        },
        toggleAdminPanel() {
            if(this.isAdminPanel){
                this.$store.dispatch('setAdminPanel', false)
                this.$store.dispatch('changeCurrentView', 0)
            }
            else {
                this.$store.dispatch('setAdminPanel', true)
                this.$router.push('/admin');
            }
            
        },
        logout() {
            this.$store.dispatch('auth/logout').then(
                () => {
                    this.$router.push('/login');
                },
                error => {
                    this.loading = false;
                    this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                }
            );
            //AuthService.logout();
            //this.$store.dispatch('auth/logout');
            //this.$router.push('/login');
        },
        showSelectFinca() {
            this.selectFinquesVisible = true
        },
        selectFinca(finca) {
            this.$store.dispatch('changeSelectedFinca', finca)
            this.selectFinquesVisible = false
            this.$store.dispatch('changeCurrentView', 0)
        }
    }
}
</script>