import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

import { auth } from './auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    admin: {
      isAdminPanel: false,
      currentTab: 0,
    },
    selectedVillage: 0,
    selectedEntity: {
      type: '',
      id: '',
      name: ''
    },
    types: ['Especific', 'Salut', 'Entitats', 'Espais', 'Menjar', 'Dormir', 'Comerç', 'Aparcament', 'PIT'],
    ui: {
      currentView: 0, // 0.- Home / 1.- Tecnic / 2.- Records / 3.- Documents / 4.- Webmap
      status: 0 // 0.- Normal / 1.- Loading / 2.- Error
    },


    finques: {
      userFinques: [],
      selectedFinca: null
    },
    
    documents: {
      images: [],
      videos: [],
      audios: [],
      docs: []
    },

    isLoading: false
  },
  mutations: {
    toggleAdminPanel(state, adminPanel) {
      state.admin.isAdminPanel = adminPanel
    },
    setAdminCurrentTab(state, tab) {
      state.admin.currentTab = tab
    },
    setSelectedVillage(state, data) {
      state.selectedVillage = data
    },
    setSelectedEntity(state, data) {
      state.selectedEntity = data
    },
    setUserFinques(state, userFinques ){
      state.finques.userFinques = userFinques
    },
    setSelectedFinca(state, selectedFinca ){
      state.finques.selectedFinca = selectedFinca
    },
    setCurrentView(state, view) {
      state.ui.currentView = view
    },
    setUIStatus(state, status) {
      state.ui.status = status
    },
    setDocuments(state, documents) {
      state.documents[documents[0]].push(documents[1])
    },
    resetDocuments(state) {
      state.documents.images = []
      state.documents.videos = []
      state.documents.audios = []
      state.documents.docs = []
    },
    setIsLoading(state, data) {
      state.isLoading = data
    }
  },
  actions: {
    setAdminPanel({ commit }, adminPanel) {
      commit('toggleAdminPanel', adminPanel)
    },
    setAdminTab({ commit }, tab) {
      commit('setAdminCurrentTab', tab)
    },
    changeSelectedVillage({ commit }, data) {
      commit('setSelectedVillage', data)
    },
    changeSelectedEntity({ commit }, data) {
      commit('setSelectedEntity', data)
    },
    changeUserFinques({ commit }, userFinques) { 
      commit('setUserFinques', userFinques)
    },
    changeSelectedFinca({ commit }, selectedFinca) { 
      commit('setSelectedFinca', selectedFinca)
      commit('resetDocuments')
    },
    changeCurrentView({ commit }, currentView) {
      commit('setCurrentView', currentView)
      if (currentView == 0) router.push('/')
      else if (currentView == 1) router.push('/tecnic')
      else if (currentView == 2) router.push('/records')
      else if (currentView == 3) router.push('/documents')
      else if (currentView == 4) router.push('/webmap')
    },
    changeUIStatus({ commit }, status) {
      commit('setUIStatus', status)
    },
    changeDocuments({ commit }, documents) {
      commit('setDocuments', documents)
    },
    changeIsLoading({ commit }, data) {
      commit('setIsLoading', data)
    }
  },
  getters: {
    selectedVillage: state => { return state.selectedVillage },
    selectedEntity: state => { return state.selectedEntity },
    types: state => { return state.types },
    isLoading: state => { return state.isLoading }
  },
  modules: {
    auth
  }
})
