import { render, staticRenderFns } from "./AdminVillageLocations.vue?vue&type=template&id=0968bc22&scoped=true&"
import script from "./AdminVillageLocations.vue?vue&type=script&lang=js&"
export * from "./AdminVillageLocations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0968bc22",
  null
  
)

export default component.exports