<template>
    <div class="">
        <div v-if="editEvent" class="admin-sections-header-text">Editar esdeveniment</div>
        <div v-else class="admin-sections-header-text">Nou esdeveniment</div>
        <div class="separator"></div>
        <div v-if="!saveOk">
            <label for="name">Títol</label>
            <input type="text" name="title" id="title" v-model="newLoc.title">
            <label for="desc">Descripció</label>
            <textarea name="desc" id="desc" v-model="newLoc.desc" style="margin-bottom: 10px;"></textarea>
            <label for="type">Data</label>
            <div style="display:flex; flex-direction: row;">
                <select v-model="newLoc.day" style="width: 80px;">
                    <option value="" selected>Dia</option>
                    <option v-for="index in 31" :key="index" :value="index">{{ index }}</option>
                </select>
                <select v-model="newLoc.month">
                    <option value="" selected>Mes</option>
                    <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
                </select>
            </div>
            <label for="name">Data text</label>
            <input type="text" name="datetext" id="datetext" v-model="newLoc.datetext">
            <label for="long">Long</label>
            <input type="text" name="long" id="long" v-model="newLoc.long">
            <label for="lat">Lat</label>
            <input type="text" name="lat" id="lat" v-model="newLoc.lat">
            
            <div v-if="formError" class="error-container">Hi ha camps obligatoris sense omplir</div>
            <div v-else style="height: 20px;"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Cancel·lar</div>
                <div v-if="editEvent" class="custom-button button-green" @click="saveLoc()">Actualitzar</div>
                <div v-else class="custom-button button-green" @click="saveLoc()">Crear esdeveniment</div>
            </div>
        </div>
        <div v-else>
            <div v-if="editEvent">Esdeveniment actualitzat</div>
            <div v-else>Nou esdeveniment creat</div>
            <div class="buttons-container">
                <div class="custom-button button-green" @click="closeModal()">OK</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { post } from '@/services/api.js'
// TIMEPICKER Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// TIMEPICKER CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    name: 'AdminNewEvent',
    /*computed: {
        currentAdminTab() {
            return this.$store.state.admin.currentTab;
        },
    },*/
    components: { 
        VueTimepicker
    },
    props: {
        editEvent: '',
        villageId: ''
    },        
    data() {
        return {
            newLoc: {
                title: '',
                desc: '',
                day: '',
                month: '',
                datetext: '',
                long: '',
                lat: ''
            },
            formError: false,
            saveOk: false,
            months: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre']
        }
    },
    mounted() {
        if(this.editEvent) this.loadLoc()
    },
    methods: {
        loadLoc() {
            this.newLoc.title = this.editEvent.title
            this.newLoc.desc = this.editEvent.desc
            if(this.editEvent.date_day) {
                this.newLoc.day = this.editEvent.date_day
            }
            if(this.editEvent.date_month) {
                this.newLoc.month = this.editEvent.date_month
            }
            this.newLoc.datetext = this.editEvent.date_show
            this.newLoc.long = this.editEvent.long
            this.newLoc.lat = this.editEvent.lat

        },
        closeModal() {
            this.$parent.closeEditEvent()
        },
        saveLoc() {
            if(this.newLoc.name == '' || this.newLoc.type == '' || this.newLoc.long == '' || this.newLoc.lat == '') {
                this.formError = true
            }
            else {
                let objectToSend = {}
                objectToSend.villageid =  this.villageId
                objectToSend.title =  this.newLoc.title
                objectToSend.desc =  this.newLoc.desc
                objectToSend.date_day =  this.newLoc.day
                objectToSend.date_month =  this.newLoc.month
                objectToSend.date_show =  this.newLoc.datetext
                objectToSend.long =  this.newLoc.long
                objectToSend.lat =  this.newLoc.lat

                let url = 'events'
                if(this.editEvent) {
                    url += '/' + this.editEvent.id
                }
                post(url, objectToSend)
                .then(response => {
                    this.saveOk = true
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('user')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                })
            }
        }
    }
}
</script>
<style scoped>

</style>