<template>
  <div class="home">
    <Header></Header>
    <div class="home-master-container">
      <Loader v-if="isLoading"></Loader>
      <!-- <AdminMenu></AdminMenu> -->
      <div class="container home-container">

            <div class="row h-100">
              <div class="col-sm admin-content align-self-start">
                
                <!-- <div class="admin-sections-header-text" v-if="currentAdminTab==0">Benvingut, Admin</div> -->
                <AdminVillages v-if="currentAdminTab==0"></AdminVillages>
                <AdminVillageLocations v-if="currentAdminTab==1"></AdminVillageLocations>
                <AdminVillageEvents v-if="currentAdminTab==2"></AdminVillageEvents>
                <AdminMedia v-if="currentAdminTab==3"></AdminMedia>
              </div>
            </div>  

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import AdminMenu from '@/components/AdminMenu.vue'
//import AdminUsers from '@/components/AdminUsers.vue'
import AdminNewVillage from '@/components/AdminNewVillage.vue'
import AdminVillageLocations from '@/components/AdminVillageLocations.vue'
import AdminVillageEvents from '@/components/AdminVillageEvents.vue'
import AdminVillages from '@/components/AdminVillages.vue'
import AdminMedia from '@/components/AdminMedia.vue'
import Loader from '@/components/Loader.vue'

export default { 
  name: 'Admin',
  components: {
    Header,
    AdminMenu,
    //AdminUsers,
    AdminNewVillage,
    AdminVillageLocations,
    AdminVillageEvents,
    AdminVillages,
    AdminMedia,
    Loader
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentAdminTab() {
      return this.$store.state.admin.currentTab;
    },
    isLoading () {
      return this.$store.getters.isLoading
    }
  },
  mounted() {
    document.title = 'Espai Poble - Admin panel'
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    // if (this.currentUser.user.name != 'admin') {
    //   this.$store.dispatch('changeCurrentView', 0)
    // }
  }
}
</script>
<style scoped>
.admin-content {
  padding: 20px;
  /*background-color: rgb(238, 238, 238);*/
  margin: 10px;
  height: calc(100% - 30px);
  border-radius: 15px;
}
</style>
