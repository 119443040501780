<template>
    <div class="">
        <div class="admin-sections-header-text" style="height: 32px;">
            <div style="flex: 1;">
                <img :src="require('@/assets/img/icon_image.png')" alt="Media files" class="header-icon">
                Archius multimedia de {{ selectedEntity.name }}
            </div>

            <!-- New file -->
            <div v-if="selectedEntity.type != 'village'">
                <input
                    v-if="selectedEntity.type == 'location' || !isMultimedia"
                    type="file"
                    ref="mediaInput"
                    style="display: none"
                    accept="image/*"
                    @change="onFilePicked"
                />
                <input
                    v-else
                    type="file"
                    ref="mediaInput"
                    style="display: none"
                    accept="audio/*,.mp4"
                    @change="onFilePicked"
                />
                <div v-if="selectedEntity.type != 'event'" class="custom-button button-gray-green" @click="onPickFile()"  style="margin-right: 0; height: 100%; padding: 5px 20px;">
                    <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 10px; margin-right: 8px;">Afegir imatge
                </div>
                <div v-else class="custom-button button-gray-green" @click="toggle_show_modal()"  style="margin-right: 0; height: 100%; padding: 5px 20px;">
                    <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 10px; margin-right: 8px;">Afegir archiu
                </div>
            </div>

            <div v-if="selectedEntity.type=='village'" class="custom-button button-gray" style="margin-right: 0; height: 100%; padding: 2px 20px;" v-on:click="backTo(0)">Tornar a Pobles</div>
            <div v-else-if="selectedEntity.type=='location'" class="custom-button button-gray" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="backTo(1)">Tornar a Localitzacions</div>
            <div v-else-if="selectedEntity.type=='event'" class="custom-button button-gray" style="margin-right: 0; height: 100%; padding: 5px 20px;" v-on:click="backTo(2)">Tornar a Esdeveniments</div>
        </div>
        <div class="separator"></div>
        Imatge principal
        <MediaElement v-if="loaded_main" :media="main_media"></MediaElement>
        <div style="height: 30px;"></div>
        <div v-if="selectedEntity.type=='village'">
            Bandera / Escut
        </div>
        <MediaElement v-if="loaded_flag" :media="others_media[0]"></MediaElement>

 
        <div v-if="selectedEntity.type != 'village'">
            Archius multimèdia
            <div v-if="loaded_others">
                <MediaElement v-for="(mediaelement, index) in others_media" v-bind:key="index" :media="others_media[index]"></MediaElement>
            </div>
        </div>

        <!-- *********  MODAL NEW FILE  ********** -->
        <div class="modal-new-file" v-if="show_modal">
            <label for="desc">Descripció</label>
            <textarea name="desc" id="desc" v-model="desc" style="margin-bottom: 10px;"></textarea>
            <div style="display: flex; flex-direction: row; align-items: center;">
                <!-- <label for="checkbox" style="color: grey; font-weight: 400;">Àudio / Vídeo</label>
                <input type="checkbox" id="checkbox" v-model="isMultimedia"> -->
                <div style="flex: 1;"></div>
                <div class="custom-button button-gray" @click="toggle_show_modal()"  style="margin-right: 0; height: 100%; padding: 5px 20px;">
                    Cancel·lar
                </div>
                <div v-if="!isMultimedia" class="custom-button button-gray-green" @click="onPickFile()"  style="margin-right: 0; height: 100%; padding: 5px 20px;">
                    <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 10px; margin-right: 8px;">Afegir imatge
                </div>
                <div v-else class="custom-button button-gray-green" @click="onPickFile()"  style="margin-right: 0; height: 100%; padding: 5px 20px;">
                    <img :src="require('@/assets/img/iconnew.png')" alt="add file" class="table-icon" style="width: 10px; margin-right: 8px;">Afegir arxiu
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { get, post, getFileDocs, uploadFile } from '@/services/api.js'
import AdminNewEvent from '@/components/AdminNewEvent.vue'
import MediaElement from '@/components/MediaElement.vue'

export default {
    name: 'AdminMedia',
    components: {
        AdminNewEvent,
        MediaElement
    },
    data() {
        return {
            main_media: {},
            loaded_main: false,
            loaded_flag: false,
            loaded_others: false,
            others_media: [],
            others_audio_video: [],
            mediaToDelete: null,
            mediaDeletedOk: false,
            show_modal: false,
            desc: '',
            isMultimedia: false,
            nameToSetMutimedia: '',
            image_types: ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG"],
            media_types: ["avi", "AVI", "mpeg", "MPEG", "mpg", "MPG", "mp4", "MP4", "wav", "WAV", "mp3", "MP3"]
        }
    },
    computed: {
        selectedEntity () {
            return this.$store.getters.selectedEntity
        }
    },
    mounted () {
        this.getEntityFiles()
    },
    watch: {
        text_filter: function (val) {
            this.filterEvents(val)
        },
    },
    methods: {
        backTo(tab) {
            this.$store.dispatch('setAdminTab', tab)
        },
        getEntityFiles() {
            this.loaded_main = false
            this.loaded_flag = false
            this.loaded_others = false
            this.main_media = {}
            this.others_media = []
            this.others_audio_video = []
            let url = 'files/' + this.selectedEntity.type + 's/' + this.selectedEntity.id 

            get(url)
            .then(response => {
                if(this.selectedEntity.type == 'village') {
                    this.main_media.entity = 'village'
                    this.main_media.id = this.selectedEntity.id
                    this.main_media.ismain = 'true'
                    this.main_media.path = response.image
                    let flag = {}
                    flag.entity = 'village'
                    flag.id = this.selectedEntity.id
                    flag.ismain = 'false'
                    flag.path = response.flag
                    this.others_media.push(flag)
                    this.getFiles()
                }
                else {
                    this.main_media.entity = this.selectedEntity.type
                    this.main_media.id = this.selectedEntity.id
                    this.main_media.ismain = 'true'
                    this.main_media.path = response.main

                    if(this.selectedEntity.type == 'location') {
                        response.others.forEach(element => {
                            let object = {}
                            object.path = element
                            this.others_media.push(object)
                        });
                    }
                    else {
                        response.others.forEach(element => {
                            let object = {}
                            object.path = element.file
                            object.id = element.eventid
                            object.mediaid = element.id
                            object.desc = element.desc
                            let extension = element.file.split('.').pop()
                            if(this.image_types.includes(extension)) {                           
                                this.others_media.push(object)
                            }
                            else {
                                this.others_audio_video.push(object)
                            }
                        });
                    }
                    this.getFiles()
                }
            })
        },
        getFiles() {
            if(this.main_media.path != '') {
                let objectToSend = {}
                objectToSend.path =  this.main_media.path
                getFileDocs ('files/getfile', objectToSend)
                .then(response => {
                    this.main_media.url = response
                    this.loaded_main = true
                })
            }
            else {
               this.loaded_main = true
            }

            if(this.others_media.length > 0) {
                if(this.selectedEntity.type == 'village' && this.others_media[0].path == '') {
                    this.others_media[0].filetype = 'image'
                    this.loaded_flag = true
                }
                else {
                    this.getFileSequential(0)
                }
                
            }
            else {
                this.loaded_others = true
            }
        },
        getFileSequential (index) {
            let objectToSend = {}
            objectToSend.path =  this.others_media[index].path
            let extension = this.others_media[index].path.split(".").pop().toUpperCase()
            if(extension == 'JPG' || extension == 'PNG'){
                getFileDocs ('files/getfile', objectToSend)
                .then(response => {
                    this.others_media[index].url = response
                    this.others_media[index].entity = this.selectedEntity.type
                    this.others_media[index].filetype = 'image'
                    if(this.selectedEntity.type != 'event') {
                        this.others_media[index].id = this.selectedEntity.id
                    }              
                    this.others_media[index].ismain = 'false'
    
                    if(this.selectedEntity.type == 'village') {
                        this.loaded_flag = true
                    }
                    if (index < this.others_media.length -1) {
                        this.getFileSequential(index +1)
                    }
                    else {
                        this.loaded_others = true
                        if (this.others_audio_video.length > 0) {
                            this.linkMediaWithImage()
                        }
                    }
                })
            }
            else {
                this.others_media[index].url = undefined
                this.others_media[index].entity = this.selectedEntity.type       
                if(this.selectedEntity.type != 'event') {
                    this.others_media[index].id = this.selectedEntity.id
                }              
                this.others_media[index].ismain = 'false'
                if(extension == 'MP4'){
                    this.others_media[index].filetype = 'video'
                }
                else if(extension == 'MP3' || extension == 'WAV'){
                    this.others_media[index].filetype = 'audio'
                }
                else {
                    this.others_media[index].filetype = 'unknown'
                }
                if (index < this.others_media.length -1) {
                    this.getFileSequential(index +1)
                }
                else {
                    this.loaded_others = true                 
                }
            }
            
            
        },
        onPickFile () {
            this.$refs.mediaInput.click()
        },
        onFilePicked (event) {      
            let extension = event.target.files[0].name.split('.').pop()
            if((!this.isMultimedia && this.image_types.includes(extension)) || (this.isMultimedia && this.media_types.includes(extension))) {           
                let fileToUpload
                if (this.isMultimedia) {
                    fileToUpload = this.renameFile(event.target.files[0], this.nameToSetMutimedia + "." + extension)
                    console.log(fileToUpload)
                }
                else {
                    fileToUpload = event.target.files[0]
                }
                this.show_modal = false
                
                let url = 'files/store'
                const formData = new FormData();
                formData.append('entity', this.selectedEntity.type)
                formData.append('File', fileToUpload)
                formData.append('id', this.selectedEntity.id)
                formData.append('ismain', 'false')
                formData.append('desc', this.desc)

                this.$store.dispatch("changeIsLoading", true)

                uploadFile(url, formData)
                .then(response => {
                    this.getEntityFiles()
                    this.$store.dispatch("changeIsLoading", false)
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('user')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                    this.$store.dispatch("changeIsLoading", false)
                })    
            }      
        },
        toggle_show_modal () {
            if (!this.show_modal) {
                this.isMultimedia = false;
            }
            this.show_modal = !this.show_modal           
        },
        linkMediaWithImage () {
            this.others_audio_video.forEach(audioVideo => {
                let audioVideoName = audioVideo.path.substring(0, audioVideo.path.indexOf("."))
                console.log(audioVideoName)
                this.others_media.forEach(othersImage => {
                    let imageName = othersImage.path.substring(0, othersImage.path.indexOf("."));
                    console.log(imageName)
                    if(audioVideoName == imageName) {
                        console.log(audioVideo.path)
                        othersImage.multimedia_path = audioVideo.path
                        othersImage.multimedia_mediaid = audioVideo.mediaid
                        let extension = audioVideo.path.split(".").pop().toUpperCase()
                        if(extension == 'WAV' || extension == 'MP3'){
                            othersImage.multimedia_type = "audio"
                        }
                        else if(extension == 'MP4') {
                            othersImage.multimedia_type = "video"
                        }
                    }
                    
                })
            })
        },
        renameFile (originalFile, newName) {
            return new File([originalFile], newName, {
                type: originalFile.type,
                lastModified: originalFile.lastModified,
            });
        }
    }
}
</script>
<style scoped>
.separator {
    height: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-bottom: 20px;
}
.modal-new-file {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 3px 5px 2px rgba(0,0,0,0.2);
    padding: 30px;
}
label {
    margin: 0px 12px 0px 0px;
}
</style>