<template>
    <div class="">
        <div v-if="editLoc" class="admin-sections-header-text">Editar localització</div>
        <div v-else class="admin-sections-header-text">Nova localització</div>
        <div class="separator"></div>
        <div v-if="!saveOk">
            <label for="name">Nom</label>
            <input type="text" name="name" id="name" v-model="newLoc.name">
            <label for="type">Tipus <span style="font-size: 0.8rem; opacity: 0.7;"> (ctrl o cmd per a selecció múltiple)</span></label>
            <!-- <input type="text" name="type" id="type" v-model="newLoc.type"> -->
            <select
                multiple="true"
                v-bind:class="{ 'fix-height': multiple === 'true' }"
                v-model="newLoc.type"
            >
                <option
                    v-for="index in 9"
                    :value="index - 1"
                    v-bind:key="index"
                >
                    {{types[index - 1]}}
                </option>
            </select>
            <label for="desc">Descripció</label>
            <textarea name="desc" id="desc" v-model="newLoc.desc" style="margin-bottom: 10px;"></textarea>
            <label for="long">Long</label>
            <input type="text" name="long" id="long" v-model="newLoc.long">
            <label for="lat">Lat</label>
            <input type="text" name="lat" id="lat" v-model="newLoc.lat">
            <label for="lat">Horari</label>
            <input type="text" name="opentime" id="opentime" v-model="newLoc.opentime">
            <!-- <div style="display:flex; flex-direction: row; align-items: center;">
            Des de <vue-timepicker v-model="newLoc.open" style="margin: 0px 10px;"></vue-timepicker> Fins <vue-timepicker v-model="newLoc.close" style="margin: 0px 10px;"></vue-timepicker>
            </div> -->
            <label for="phone">Telèfon</label>
            <input type="text" name="phone" id="phone" v-model="newLoc.phone">
            <label for="website">Lloc web</label>
            <input type="text" name="website" id="website" v-model="newLoc.website">
            <label for="address">Adreça</label>
            <input type="text" name="address" id="address" v-model="newLoc.address">
            <div v-if="formError" class="error-container">Hi ha camps obligatoris sense omplir</div>
            <div v-else style="height: 20px;"></div>
            <div class="buttons-container">
                <div class="custom-button button-gray" @click="closeModal()">Cancel·lar</div>
                <div v-if="editLoc" class="custom-button button-green" @click="saveLoc()">Actualitzar</div>
                <div v-else class="custom-button button-green" @click="saveLoc()">Crear localització</div>
            </div>
        </div>
        <div v-else>
            <div v-if="editLoc">Localització actualitzada</div>
            <div v-else>Nova localització creada</div>
            <div class="buttons-container">
                <div class="custom-button button-green" @click="closeModal()">OK</div>
            </div>
        </div>
    </div>
    
</template>
<script>
import { post } from '@/services/api.js'
// TIMEPICKER Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// TIMEPICKER CSS
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    name: 'AdminNewLoc',
    /*computed: {
        currentAdminTab() {
            return this.$store.state.admin.currentTab;
        },
    },*/
    components: { 
        VueTimepicker
    },
    props: {
        editLoc: '',
        villageId: ''
    },
    computed: {
        types () {
            return this.$store.getters.types
        }
    },       
    data() {
        return {
            newLoc: {
                name: '',
                type: [],
                desc: '',
                long: '',
                lat: '',
                // open: {
                //     HH: '',
                //     mm: ''
                // },
                // close: {
                //     HH: '',
                //     mm: ''
                // },
                opentime: '',
                phone: '',
                website: '',
                address: ''
            },
            multiple: "true",
            formError: false,
            saveOk: false
        }
    },
    mounted() {
        if(this.editLoc) this.loadLoc()
    },
    methods: {
        loadLoc() {
            this.newLoc.name = this.editLoc.name
            //this.newLoc.type = this.editLoc.type
            let typeArray = this.editLoc.type.split(",");
            for(let i = 0; i < typeArray.length; i++) {
                if(i == 0) {
                    typeArray[i] = typeArray[i].substring(1)
                }
                if(i == typeArray.length -1) {
                    typeArray[i] = typeArray[i].slice(0, -1)
                }
            }
            this.newLoc.type = typeArray

            this.newLoc.desc = this.editLoc.desc
            this.newLoc.long = this.editLoc.long
            this.newLoc.lat = this.editLoc.lat
            // if(this.editLoc.open) {
            //     let openTimeSplit = this.editLoc.open.split(':')
            //     this.newLoc.open.HH = openTimeSplit[0]
            //     this.newLoc.open.mm = openTimeSplit[1]
            // }
            // if(this.editLoc.close) {
            //     let closeTimeSplit = this.editLoc.close.split(':')
            //     this.newLoc.close.HH = closeTimeSplit[0]
            //     this.newLoc.close.mm = closeTimeSplit[1]
            // }
            this.newLoc.opentime = this.editLoc.opentime
            this.newLoc.phone = this.editLoc.phone
            this.newLoc.website = this.editLoc.website
            this.newLoc.address = this.editLoc.address
        },
        closeModal() {
            this.$parent.closeEditLoc()
        },
        saveLoc() {
            if(this.newLoc.name == '' || this.newLoc.type == '' || this.newLoc.long == '' || this.newLoc.lat == '') {
                this.formError = true
            }
            else {
                let typeString = '['
                for(let i = 0; i < this.newLoc.type.length; i ++) {
                    typeString += this.newLoc.type[i].toString()
                    if(i < this.newLoc.type.length -1) {
                      typeString += ','   
                    }
                }

                typeString += ']' 
                let objectToSend = {}
                objectToSend.villageid =  this.villageId
                objectToSend.name =  this.newLoc.name
                objectToSend.type =  typeString
                objectToSend.desc =  this.newLoc.desc
                objectToSend.long =  this.newLoc.long
                objectToSend.lat =  this.newLoc.lat
                // if(this.newLoc.open.HH != '' && this.newLoc.open.mm != ''){
                //     objectToSend.open = this.newLoc.open.HH + ':' + this.newLoc.open.mm
                // }
                // else {
                //     objectToSend.open = null
                // }
                // if(this.newLoc.close.HH != '' && this.newLoc.close.mm != ''){
                //     objectToSend.close = this.newLoc.close.HH + ':' + this.newLoc.close.mm
                // }
                // else {
                //     objectToSend.close = null
                // }
                objectToSend.opentime =  this.newLoc.opentime
                objectToSend.phone =  this.newLoc.phone
                objectToSend.website =  this.newLoc.website
                objectToSend.address =  this.newLoc.address
                console.log(objectToSend)

                let url = 'locations'
                if(this.editLoc) {
                    url += '/' + this.editLoc.id
                }
                post(url, objectToSend)
                .then(response => {
                    this.saveOk = true
                })
                .catch(error => {
                    console.log("ERROR: ", error.response.data.error)
                    if(error.response.data.error.includes("token")){
                        localStorage.removeItem('user')
                        this.$store.dispatch('auth/logout')
                        this.$router.push('/login')
                    }
                })
            }
        }
    }
}
</script>
<style scoped>

</style>